import * as React from 'react';
import { useEffect, useState } from 'react';
import Firebase from '@/modules/firebase';
import { instance, setCookie } from '@/lib/index';
import { AxiosResponse } from 'axios';
import Router, { useRouter } from 'next/router';
import { userToken } from '@/lib/constants';
import { NextPage } from 'next';
import ImageComponent from './shared/image';
import { createLogger } from '@/modules/logging/logger';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';

interface LoginProps {
  pathToRedirect: string;
}

const logger = createLogger({
  fileLabel: `components/Login`,
});

export const Login: NextPage<LoginProps> = ({ pathToRedirect = '/dashboard' }) => {
  const router = useRouter();

  useEffect(() => {
    // Prefetch the dashboard page
    router.prefetch('/dashboard');
  }, [router]);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleLogin = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setLoading(true);
    if (!(email && password)) {
      setError('Please fill in email and password');
      // setSignInText('Sign In');
      // setLoading(false);
      return;
    }
    let isError = false;
    Firebase.login(Firebase.EMAIL, { email, password })
      .then((result) => {
        if (isError) {
          return;
        }
        setCookie(userToken, result.user.uid);
        Router.push(pathToRedirect);
        instance
          .post('/api/login', { data: { firebase: result.user } })
          .then((res: AxiosResponse) => {
            const user = res.data;
            if (user.admin) {
              setCookie('id_token_a', 'true');
            }
          })
          .catch((e) => {
            logger.error(e);
          });
      })
      .catch((result) => {
        setError(result.message);
        setLoading(false);
        isError = true;
      });
  };

  return (
    <div className={'pv1 tc '}>
      <>
        <h1 className="mb0  mt-16 pt3 mb0 mt3 pv3 f2-ns md:text-5xl text-3xl font-bold font-grotesk-md">Login</h1>
        <div className="h-3 mb-8 md:w-[40%] mx-auto relative  ">
          <ImageComponent width="380" height={'12'} src="/static/images/tba-strip-basic.png" />
        </div>
        <form className="dib w-100 mw6 center ">
          <div className="mv3">
            <div className="mv3 tl border-2 border-gray-400 bg-black  ">
              <input
                id="input-login-email"
                className="pl2 input-reset  bg-transparent h-14 w-full autofill-black placeholder:text-gray-01 text-lg font-semibold"
                type="email"
                value={email}
                placeholder={'Email'}
                onChange={(event) => {
                  setEmail(event.currentTarget.value);
                  setError('');
                }}
              />
            </div>
          </div>
          <div className="mv3 tl border-2 border-gray-400 bg-black ">
            <input
              id="input-login-password"
              className="pl2 input-reset h-14 bg-transparent w-full autofill-black placeholder:text-gray-01 text-lg"
              type="password"
              value={password}
              placeholder={'Password'}
              onChange={(event) => {
                setError('');
                setPassword(event.currentTarget.value);
              }}
            />
          </div>
          <small id="name-desc" className="hljs-strong text-red-700 f6 db mv3">
            {error}
          </small>

          <div className="fl dib pt3 flex flex-col w-full">
            <button
              onClick={(e) => {
                trackAction({
                  action: AMPLITUDE_ACTIONS.CLICK,
                  event: AMPLITUDE_EVENTS.USER_LOG_IN,
                  opts: { email }
                })
                handleLogin(e);
              }}
              className="b--white  bg-white text-black db dim noselect fl ba bw2 rounded-lg  b--solid pa2 ph4 f4 fw5">
              {loading && <i className="fa fa-spinner fa-spin mr2" />}
              {loading ? 'Logging in...' : 'Login'}
            </button>
            <a className="white mt-3" href="/forgot">
              Forgot password?
            </a>
          </div>
        </form>
      </>
    </div>
  );
};
