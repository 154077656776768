import classNames from 'classnames';
import Image from "next/legacy/image";
import { generateUrl } from '@/lib/index';

const ImageComponent = ({ alt = '', src, width, height, className = '' }) => {
  return (
    <Image
      className={className}
      src={src}
      width={width}
      height={height}
      layout="responsive"
      // quality={100}
      alt={alt}
      loading={'lazy'}
    />
  );
};

export default ImageComponent;
